import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faLinkedin,
  faMedium,
  faStackOverflow,
} from "@fortawesome/free-brands-svg-icons";
import {
  Box,
  HStack,
  VStack,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useScrollContext } from "../context/scrollContext";

const socials = [
  { icon: faEnvelope, url: "mailto: diogofmrodrigues03@gmail.com" },
  { icon: faGithub, url: "https://github.com/Diogofmr" },
  { icon: faLinkedin, url: "https://www.linkedin.com/in/Diogo-fmr" },
  { icon: faMedium, url: "https://medium.com" },
  { icon: faStackOverflow, url: "https://stackoverflow.com" },
];

const Header = () => {
  const { transformStyle } = useScrollContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Do not hide the header on mobile devices
  const headerStyle = useBreakpointValue({
    base: "none", 
    md: transformStyle,
  });

  const handleClick = (anchor) => () => {
    const id = `${anchor}-section`;
    const element = document.getElementById(id);
    if (anchor === "projects" && element) {
      const offset = 32;
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    } else {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setIsMenuOpen(false); // Close menu after clicking a link
  };

  return (
    <Box
      position="fixed"
      as="header"
      top="0"
      left="0"
      right="0"
      transition="transform 0.3s ease"
      backgroundColor="#18181b"
      // Apply the transformStyle to the header if is not on a mobile device
      style={{ transform: headerStyle }}
      zIndex="1000"
    >
      <Box color="white" maxWidth="1950px" margin="0 auto">
        {/* Hamburger Menu for Mobile */}
        <IconButton
          style={{ position: "absolute", right: "1rem", top: "1rem" }}
          display={{ base: "block", md: "none" }}
          icon={
            isMenuOpen ? (
              <FontAwesomeIcon icon={faTimes} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )
          }
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Toggle Menu"
        />

        {/* Desktop Header Content */}
        <Box
          display={{ base: "none", md: "block" }}
          px={16}
          py={4}
          color="white"
        >
          <HStack justifyContent="space-between" alignItems="center">
            <HStack spacing={6}>
              {socials.map((social) => (
                <a href={social.url} key={social.url}>
                  <FontAwesomeIcon icon={social.icon} size="2x" />
                </a>
              ))}
            </HStack>
            <HStack spacing={8}>
              <a
                href="#contactme"
                onClick={handleClick("contactme")}
                style={{ cursor: "pointer" }}
              >
                Contact Me
              </a>
              <a
                href="#projects"
                onClick={handleClick("projects")}
                style={{ cursor: "pointer" }}
              >
                Projects
              </a>
            </HStack>
          </HStack>
        </Box>
      </Box>

      {/* Drawer for Mobile Menu */}
      <Drawer
        isOpen={isMenuOpen}
        placement="right"
        onClose={() => setIsMenuOpen(false)}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <VStack spacing={6} mt={4} align="start">
              {/* Social Links */}
              <VStack spacing={4} align="start">
                {socials.map((social) => (
                  <a href={social.url} key={social.url}>
                    <HStack>
                      <FontAwesomeIcon icon={social.icon} />
                      <span>
                        {social.url.replace(/https?:\/\/(www\.)?/, "")}
                      </span>
                    </HStack>
                  </a>
                ))}
              </VStack>

              {/* Navigation Links */}
              <a href="#contactme" onClick={handleClick("contactme")}>
                Contact Me
              </a>
              <a href="#projects" onClick={handleClick("projects")}>
                Projects
              </a>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Header;
