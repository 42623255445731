import {Input} from "@chakra-ui/react";

/**
 * Doesn't need state because the formik deals with it
 * binds the value to the current value from formik0s values
 * binds onChange tp update the input
 * binds onBlur that marks the fields as touched for validation purposes
 */

const InputFormik = ({ name, type, formik }) => {
  return (
    <Input
      {...formik.getFieldProps(name)}
      id={name}
      name={name}
      type={type}
    />
  )
}

export default InputFormik;