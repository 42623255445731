import { useState } from "react";
import emailjs from "@emailjs/browser";

const PARAMS = {
  serviceID: "service_1sqfi54",
  templateID: "template_4s29mfp",
  publicKey: "BFhmpQCFTL9hgL_Ei",
};

/**
 * This is a custom hook that can be used to submit a form and simulate an API call
 * It uses Math.random() to simulate a random success or failure, with 50% chance of each
 */
const useSubmit = () => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const submit = async (data) => {
    setLoading(true);
    try {
      await emailjs.send(
        PARAMS.serviceID,
        PARAMS.templateID,
        {
          firstName: data.firstName,
          email: data.email,
          type: data.type,
          comment: data.comment,
        },
        PARAMS.publicKey
      );
      setResponse({
        type: "success",
        message: `Thanks for your submission ${data.firstName}, we will get back to you shortly!`,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setResponse({
        type: "error",
        message: "Something went wrong, please try again later!",
      });
      setLoading(false);
    }
  };

  return { isLoading, response, submit };
};

export default useSubmit;
