import React from "react";
import FullScreenSection from "./FullScreenSection";
import { Box, Heading } from "@chakra-ui/react";
import ProjectCard from "./Card";

const projects = [
  {
    title: "SCAR",
    description:
      "A Blockchain Approach for Academic Registry. A multiplatform dApp that allows students to store their academic certificates securely and allows employers to verify them",
    getImageSrc: () => require("../images/digoCertify.png"),
    url: "https://github.com/DiGo-Certify/DiGo-certify-app",
  },
  {
    title: "Gomoku Web",
    description:
      "A Full Stack Web Application for playing Gomoku online with friends. Built with React, Kotlin with Spring MVC and PostgreSQL for the database",
    getImageSrc: () => require("../images/gomoku-royale.png"),
    url: "https://github.com/astral-projects/gomoku-web",
  },
  {
    title: "Gomoku Mobile",
    description:
      "A mobile application (Android) for playing Gomoku online with friends. Built with Kotlin, Firebase and Jetpack Compose",
    getImageSrc: () => require("../images/gomoku-mobile.png"),
    url: "https://github.com/astral-projects/gomoku-mobile",
  },
  {
    title: "Autorouter",
    description:
      "The Autorouter library allows the automatic creation of HTTP handlers for a JsonServer based on a router object with specific annotations.",
    getImageSrc: () => require("../images/photo4.jpg"),
    url: "https://github.com/astral-projects/autorouter",
  },
];

const ProjectsSection = () => {
  return (
    <FullScreenSection
      backgroundColor="#14532d"
      isDarkBackground
      p={8}
      alignItems="flex-start"
      spacing={8}
    >
      <Heading as="h1" id="projects-section">
        Featured Projects
      </Heading>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2,minmax(0,1fr))"
        gridGap={8}
      >
        {projects.map((project) => (
          <ProjectCard
            key={project.title}
            title={project.title}
            description={project.description}
            imageSrc={project.getImageSrc()}
            url={project.url}
          />
        ))}
      </Box>
    </FullScreenSection>
  );
};

export default ProjectsSection;
