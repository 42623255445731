import { Heading, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const ProjectCard = ({ title, description, imageSrc, url }) => {
  return (
    <VStack
      alignItems="self-start"
      background="orange.50"
      rounded="md"
      maxW="400px"
    >
      <Image
        height="200px"
        src={imageSrc}
        rounded="lg"
        // fill max content width
        width="100%"
        // Fallback beije color
        backgroundColor={"#f5f5dc"}
      />
      <Heading size="md" padding="2" color="black">
        {title}
      </Heading>
      <Text textStyle="xs" padding="2" color="gray.500">
        {description}
      </Text>
      <HStack>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <HStack>
            <Heading size="sm" padding="2" color="black">See more</Heading>
            <FontAwesomeIcon
              icon={faArrowRight}
              size="1x"
              padding="2"
              color="black"
            />
          </HStack>
        </a>
      </HStack>
    </VStack>
  );
};

export default ProjectCard;
