import React, { useEffect } from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Spinner,
  Select,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import * as Yup from "yup";
import FullScreenSection from "./FullScreenSection";
import useSubmit from "../hooks/useSubmit";
import { useAlertContext } from "../context/alertContext";
import InputFormik from "./InputFormik";

const LandingSection = () => {
  const { isLoading, response, submit } = useSubmit();
  const { onOpen } = useAlertContext();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      type: "",
      comment: "",
    },
    onSubmit: async (values) => {
      console.log("here");
      submit(values);
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches("^[A-Za-z]+$", "Write a valid name")
        .required("Name is required"),
      email: Yup.string().email().required("Email is required"),
      type: Yup.string().required("Type of enquiry is required"),
      comment: Yup.string()
        .min(10)
        .required("Message should be at least 10 characters"),
    }),
  });

  // Listens for response changes to trigger the alerts
  useEffect(() => {
    if (response) {
      if (response.type === "success") {
        onOpen(response.type, response.message);
        formik.resetForm();
      } else {
        onOpen(response.type, response.message);
      }
    }
  }, [response]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);
    formik.handleSubmit(e);
  };

  const isFormInvalid = (fieldName) => {
    return formik.touched[fieldName] && formik.errors[fieldName];
  };

  return (
    <FullScreenSection
      isDarkBackground
      backgroundColor="#512DA8"
      py={16}
      spacing={8}
    >
      <VStack w="1024px" p={32} alignItems="flex-start">
        <Heading as="h1" id="contactme-section">
          Contact me
        </Heading>
        <Box p={6} rounded="md" w="100%">
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isInvalid={isFormInvalid("firstName")}>
                <FormLabel htmlFor="firstName">Name</FormLabel>
                <InputFormik name="firstName" formik={formik} />
                <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={isFormInvalid("email")}>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                <InputFormik name="email" type="email" formik={formik} />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={isFormInvalid("type")}>
                <FormLabel htmlFor="type">Type of enquiry</FormLabel>
                <Select
                  id="type"
                  name="type"
                  value={formik.values.type} // Connect value to Formik's state
                  onChange={formik.handleChange} // Update Formik's state on change
                  onBlur={formik.handleBlur} // Trigger validation on blur
                  _focus={{ color: "black", spacing: "10px" }}
                >
                  <option value="" label="Select type of enquiry" />
                  <option value="hireMe">
                    I want to hire you for a project
                  </option>
                  <option value="openSource">
                    I want to collaborate on an open-source project
                  </option>
                  <option value="other">Other</option>
                </Select>
              </FormControl>
              <FormControl isInvalid={isFormInvalid("comment")}>
                <FormLabel htmlFor="comment">Your message</FormLabel>
                <Textarea
                  id="comment"
                  name="comment"
                  value={formik.values.comment} // Connect to Formik state
                  onChange={formik.handleChange} // Update Formik state on change
                  height={250}
                  placeholder="I am excited to hear you!"
                />
                <FormErrorMessage>{formik.errors.comment}</FormErrorMessage>
              </FormControl>
              <Button type="submit" colorScheme="purple" width="full">
                {isLoading ? (
                  <Spinner color="colorPalette.600" size="md" />
                ) : (
                  "Submit"
                )}
              </Button>
            </VStack>
          </form>
        </Box>
      </VStack>
    </FullScreenSection>
  );
};

export default LandingSection;
