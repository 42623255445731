import React from "react";
import { Avatar, Heading, VStack } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import me from "../images/me.jpg";

const greeting = "Hello, I am Diogo!";
const bio1 = "Junior Software Developer";
const bio2 = "";

// Implement the UI for the LandingSection component according to the instructions.
// Use a combination of Avatar, Heading and VStack components.
const LandingSection = () => (
  <FullScreenSection
    justifyContent="center"
    alignItems="center"
    isDarkBackground
    backgroundColor="#2A4365"
  >
    <VStack alignItems="center">
      {/* Insert the me.jpg image into the avatar that is in src/images/me.jpg */}
      <Avatar size="2xl" name="Diogo" src={me} bgColor={"orange"}/>
      <Heading size="sm" h="20">
        {greeting}
      </Heading>
      <Heading as="h1">
        {" "}
        {bio1} <br /> {bio2}{" "}
      </Heading>
    </VStack>
  </FullScreenSection>
);

export default LandingSection;
