import {createContext, useContext, useEffect, useState} from "react";

const ScrollContext = createContext(undefined)

export const ScrollProvider = ({ children }) => {
    const [transformStyle, setTranformStyle] = useState("translateY(0)")
    let lastScrollY = 0

    // Keep tracking of the scroll
    useEffect(() => {
        const handleScroll = () => {

        const currentScrollY = window.scrollY

        if (currentScrollY > lastScrollY) {
            setTranformStyle("translateY(-200px)")
        } else {
            setTranformStyle("translateY(0)")
        }

        // Update last scropp position
        lastScrollY = currentScrollY
    }

        console.log(transformStyle)
        window.addEventListener("scroll", handleScroll)
        
        // Clean up the listener
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    return (
        <ScrollContext.Provider value={{ transformStyle }}>
            {children}
        </ScrollContext.Provider>
    )


}

export const useScrollContext = () => useContext(ScrollContext);